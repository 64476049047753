import React, { useState } from "react";
import { Box, Flex, css } from "@chakra-ui/core";
import NavigationButton from "./NavigationButton";
import useSiteMetadata from "./SiteMetadata";
import { NavigationOverlay } from "./NavigationOverlay";
import { Footer } from "../components/Footer";
import { HelmetSeo } from "./HelmetSeo";

const TemplateWrapper = ({
  children,
  seoTitle,
  hasSeo,
  seoDescription,
  hideFooter,
  pathname,
  noIndex,
  noNavButton,
}) => {
  const { title, description } = useSiteMetadata();

  const [showNavigation, setShowNavigation] = useState(false);

  const seoActualTitle = seoTitle ? seoTitle : title;
  const seoActualDescription = seoDescription ? seoDescription : description;

  return (
    <Box
      width="full"
      className={css(`
      *:focus {
        outline: 0 !important;
      }
      *:active {
        outline: 0 !important;
      }
    `)}
    >
      <HelmetSeo
        title={seoActualTitle}
        description={seoActualDescription}
        noIndex={noIndex}
        hasSeo={hasSeo}
      />
      {!noNavButton && (
        <NavigationButton
          onClick={() => setShowNavigation(!showNavigation)}
          isNavigatioShown={showNavigation}
          pathname={pathname}
        />
      )}
      <Flex flex="1" flexDirection="column">
        {children}
      </Flex>
      {!hideFooter && <Footer />}
      {showNavigation && (
        <NavigationOverlay onCloseClick={() => setShowNavigation(false)} />
      )}
    </Box>
  );
};

export default TemplateWrapper;
