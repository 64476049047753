import React from "react";
import { Flex, Box } from "@chakra-ui/core";


export const Instagram = () => {
  return (
    <Flex
      width="full"
      height="full"
      justifyContent="center"
      alignItems="center"
    >
      
        <Box as="iframe" src="https://instagram.com/p/CTpUgb0ImqE/embed" width={["280px","400px","500px","600px"]} height="400px"/>

    </Flex>
  );
};
